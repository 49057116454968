/*
  id: 서비스 구분
  title: 서비스 이름 (기본 title 태그에 적용)
  src: 소스 폴더
  version: web version
  appVersion: 출시된 appVersion
*/

module.exports = {
  services: [
    {
      id: 'plus',
      title: '티맵 플러스',
      src: 'tmap-plus',
      webVersion: '1.0.0',
      appVersion: {
        and: '8.11.0',
        ios: '8.11.0',
      },
    },
    {
      id: 'general',
      title: '공통',
      src: 'tmap-general',
    },
    {
      id: 'bridge',
      title: '브릿지',
      src: 'tmap-bridge',
    },
    {
      id: 'terms',
      title: '약관',
      src: 'tmap-terms',
    },
    {
      id: 'communication',
      title: '공지사항/이벤트',
      src: 'tmap-communication',
    },
    {
      id: 'vehicle',
      title: '차량 프로필',
      src: 'tmap-car-profile',
    },
    {
      id: 'agreement',
      title: '선택약관',
      src: 'tmap-agreement',
    },
    {
      id: 'policy',
      title: '신규약관',
      src: 'tmap-policy',
    },
    {
      id: 'weather',
      title: '날씨',
      src: 'tmap-weather',
    },
  ],
}
