import './common/styles/common/_index.scss'
import React from 'react'
import { services } from './services/.config'
import ReactDOM from 'react-dom'

const isDevelopmentBuild = /STG|DTG|DEV/.test(process.env.REACT_APP_BUILD_ENV || '') || process.env.NODE_ENV === 'development'

ReactDOM.render(
  <div>{isDevelopmentBuild ? <ServiceList /> : <TmobiIndex />}</div>,
  document.getElementById('root'),
)

function ServiceList() {
  return (
    <div className="wrap">
      <div className="logo" />
      <h1 className="title">TMAP MOBILITY</h1>
      <h2 className="build_number">Build number: {process.env.BUILD_INFO} #{process.env.BUILD_NUMBER}</h2>
      <ul className="service_list">
        {services.map(service => (
          <li className="service_list_item" key={service.id}>
            {service.title} - <a href={`/view/web/${service.id}`} className="link">{`/view/web/${service.id}`}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

function TmobiIndex() {
  return (
    <div className="wrap">
      <div className="logo" />
      <h1 className="title">TMAP MOBILITY</h1>
    </div>
  )
}
